@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Raleway&display=swap");

:root {
  --main-color: #212121;
  --steel: #22303c;
  --green: #1db954;
  --yellow: #fac402;
  --gray: #424242;
}
body {
  background-color: var(--main-color);
}

.home-div {
  height: 100vh;
  flex-direction: column;
}

.flex {
  display: flex;
}
.flex-center {
  justify-content: center;
  align-items: center;
}

.nb-container {
  width: 20vw;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid white;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  background-color: var(--gray);
  cursor: pointer;
}
.nb-image {
  width: 30%;
  height: auto;
  border-radius: 50%;
  border: 3px solid var(--green);
}
.nb-name {
  color: white;
  font-family: "Raleway";
  font-size: 20px;
}

.construction-wrapper {
  border: 1px solid white;
  background-color: var(--steel);
  width: 30vw;
  border-radius: 5%;
}
.con-inner-wrap {
  flex-direction: column;
  align-items: center;
}
.gear {
  width: 70%;
  height: auto;
  animation: gear-spin 5s linear infinite;
}

.con-words {
  text-align: center;
}
.cw-headline {
  color: var(--yellow);
  font-family: "Raleway";
  font-size: 30px;
}
.cw-body {
  color: white;
  font-family: "Raleway";
}
@keyframes gear-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
